.col-config {
    background-color: #fff;
    box-shadow: 0px 0px 8px 1px #929292;
    border-radius: 3px;
}

.chart-title {
    display: inline-block;
    font-size: 15pt;
    font-weight: 500;
    color: #545ed1;
}

.chart-title-underline {
    opacity: .25;
    margin-top: 15px;
    margin-bottom: 15px;
}