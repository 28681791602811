
.btn-menu-tab {
    background-color: #3c56e8 !important;
    font-size: .9em;
}

.btn-menu {
    background-color: #2f46c4 !important;
    font-size: .9em;
}

.btn-menu:hover {
    background-color: #223392 !important;
    transition: 250ms;
}

.btn-menu-tab:hover {
    background-color: #223392 !important;
    cursor: pointer;
    transition: 250ms;
}

.btn-menu-tab-selected {
    background-color: #223392 !important;
    font-size: .9em;
}

.btn-menu-tab-selected:hover {
    background-color: #223392 !important;
    cursor: pointer;
    transition: 250ms;
}

.btn-shadow {
    box-shadow: 2px 2px 5px #7c7c7c
}

.MuiDialogTitle-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 12px !important;
    padding-left: 12px !important;

}