.bg-light {
    background-color: #3f51b5 !important;
}


.logo {
    height: 100%;
    width: 100%;

    max-height: 80px;
    max-width: 180px;
}