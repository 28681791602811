.span-email{
    font-size: small;
    color: #6c757d;
    margin-top: 10px;
}

.MuiDialog-root#login{
    height: calc(100% - 100px);
    margin-top: 100px !important;
}
