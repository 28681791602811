.da-espaco{
    margin-left: 20px;
    padding-top:20px;
}
.fa-arrow-left{
    color:white;
    font-size: 12pt;
}
.remover-decoration{
    text-decoration: none!important;
}
.container-arrow{
    width: 80px;
    height: 40px;
    background-color: #ff7777!important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}