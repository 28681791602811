.bd-l-blue{
    border-left: .25rem solid #4e73df!important;
}
.bd-l-green{
    border-left: .25rem solid #1cc88a!important
}

.bd-l-ocean-blue{
    border-left: .25rem solid #36b9cc!important;
}
.bd-l-yellow{
    border-left: .25rem solid #f6c23e!important;
}