.bg-progressbar {
    background-color: #dddddd;
    border-radius: 5px 5px;
}

.label-progressbar {
    display: inline-block;
    font-size: 12pt !important;
    color: #5f5f5f !important;
    font-weight: 500 !important;
}

.label-value {
    text-align: right !important;
    width: 30% !important;
}

.label-name {
    width: 70% !important;
    text-align: left !important;
}