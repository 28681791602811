.nav-login{
    height: 100px;
    background-color: #3f51b5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo{
    width: 225px;
    height: 225px;
}