.meu-base-form{
    margin: 50px;
    background: #FFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.meu-text-area{
    resize: none
}
.form-text-contato{
    margin-left: 5px;
    margin-top: px;
    color: #3f51b5;

}
.color-btn{
    background-color: #3f51b5!important;
    color: #FFF;
}
.color-btn:hover{
    background-color: #0a1452!important;
    color: #FFF;
}